export const GET_DISTRIBUTOR_BY_UUID = () => {
    return `
query DistributorByUuid($uuid: String!) {
    distributorByUuid(input: { uuid: $uuid }) {
      	distributor {
        	id
        	uuid
        	name
        	language
        	linkEvo
        	linkEvoObd
    	}
      		result {
        		status
        		message
      		}
    	}
  	}
`;
};

export const GET_CAMPAIGN_BY_COUNTRY_CODE = (country, lang, env) => {
    return `
	query CampaignByCountryCode {
    campaignByCountryCode(
        input: { countryCode: "${country}", environment: ${env}, language: ${lang} }
    ) {
        campaign {
            id
            ribbonDesktop
            ribbonMobile
            ribbonTagDesktop
            ribbonTagMobile
            ribbonUrl
            menuTag
            footerTag
            menuTagAndFooterTagUrl
            adaptersPageEvoTag
            adaptersPageEvoTagUrl
            adaptersPageCaristaObdTag
            adaptersPageCaristaObdTagUrl
            appAndPricingPagesEvoTag
            appAndPricingPagesEvoTagUrl
            appAndPricingPagesCaristaObdTag
            appAndPricingPagesCaristaObdTagUrl
            promoPriceEvo
            promoPriceCaristaObd
            promoPriceBundle
            popUpTagDesktop
            popUpTagMobile
            popUpTitleDesktop
            popUpTitleMobile
            popUpButtonDesktop
            popUpButtonMobile
            popUpButtonUrl
            name
            gradient
            menuAppTag
            footerAppTag
            appPricingPlans1MonthTag
            appPricingPlans1MonthPrice
            appPricingPlans3MonthTag
            appPricingPlans3MonthPrice
            appPricingPlans12MonthTag
            appPricingPlans12MonthPrice
            popUpBodyTextDesktop
            popUpBodyTextMobile
            countryId
            startTime
            endTime
            remainingTime
            remainingTimeSeconds
            active
            ribbonAnimation
            environment
            images {
                id
                tag
                url
                locale
                originalName
            }
            pages {
                id
                tag
                metaTitle
                metaDescription
                metaKeywords
                environment
                hasChildren
            }
        }
        result {
            status
            message
            messageBag {
                messages
            }
        }
    }
}`;
}

export const GET_PAGE_BY_TAG = (tag, lang, env) => {
    return `
    query Page {
    page(input: { tag: "${tag}", language: ${lang}, environment: ${env} }) {
        page {
            id
            tag
            instructions
            legalContent
            metaTitle
            metaDescription
            metaKeywords
            hreflangTag
            canonicalHref
            environment
            headings {
                h1
                h2
                h3
                h4
            }
            createdAt
            updatedAt
            items {
                id
                tag
                title
                createdAt
                updatedAt
                images {
                    id
                    tag
                    url
                    originalName
                    locale
                    imageMeta {
                        id
                        metaTitle
                        metaAltDescription
                    }
                }
                content {
                    content
                }
            }
            images {
                id
                tag
                url
                originalName
                locale
                imageMeta {
                    id
                    metaTitle
                    metaAltDescription
                }
            }
            slideshows {
                tag
                slideshowFrames {
                    id
                    title
                    subtitle
                    url
                    index
                    createdAt
                    updatedAt
                    images {
                        imageMeta {
                            id
                            metaTitle
                            metaAltDescription
                            language
                            createdAt
                            updatedAt
                        }
                        url
                        tag
                        id
                    }
                }
            }
            childPages {
                id
                tag
                instructions
                legalContent
                metaTitle
                metaDescription
                metaKeywords
                hreflangTag
                canonicalHref
                environment
                headings {
                    h1
                    h2
                    h3
                    h4
                }
                images {
                    id
                    tag
                    url
                    originalName
                    locale
                    imageMeta {
                        id
                        metaTitle
                        metaAltDescription
                        language
                    }
                }
                items {
                    id
                    tag
                    title
                    content {
                        content
                    }
                    images {
                        id
                        tag
                        url
                        originalName
                        locale
                        createdAt
                        updatedAt
                    }
                    documents {
                        id
                        tag
                        url
                        originalName
                        locale
                    }
                }
            }
        }
    }
}`
}
