<template>
  <div>
    <transition name="fade" mode="out-in">
      <div class="default-page">
          <div class="page">
              <div class="container" v-html="appTerms?.legalContent"></div>
          </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import AppLoading from "../components/AppLoading";
import { createMetaInfo } from "@/utils/helpers";
import { useHead } from '@unhead/vue';

export default {
  components: {
    AppLoading,
  },
  setup() {
    const store = useStore();
    const loaded = ref(false);
    const appTerms = computed(() => store.state.pages.app_terms);

    onBeforeMount(() => {
      if (!store.state.pages.app_terms) {
        store.dispatch('pages/getPageByTag', { tag: 'app_terms' });
      }
    });

    onMounted(() => {
      document.body.classList.remove("overflow-hidden");
      loaded.value = true;
    });

    useHead(() => {
      return createMetaInfo({
        title: appTerms.value?.metaTitle || "Carista",
        pageMeta: appTerms.value,
      });
    });

    return {
      appTerms,
      loaded,
    };
  },
};
</script>
