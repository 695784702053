<template>
  <div :class="['slider', darkModeEnabled ? 'dark-mode' : '']" v-if="testimonials?.length">
    <div class="container">
      <div class="content">
        <div class="slider-navigation" :class="{'active': isActive}">
          <h4 class="title">{{ title }}</h4>
          <a v-if="buttonUrl && buttonText && !isMobile" :href="buttonUrl" target="_blank" class="button">
            {{ buttonText }}
          </a>
          <div v-if="!buttonUrl && !buttonText && showCounter" class="counter-position">
            <p class="slide-number">{{ comment }}</p>
            <p class="all-comments">/ {{ allComment }}</p>
          </div>
        </div>
        <a v-if="buttonUrl && buttonText && isMobile" :href="buttonUrl" class="button">
            {{ buttonText }}
          </a>
        <vueper-slides
          @ready="countComment()"
          @slide="countComment()"
          class="no-shadow"
          slide-multiple
          :visible-slides="4"
          :dragging-distance="450"
          :slide-ratio="1 / 4"
          :gap="0"
          :breakpoints="{
            1400: {
              visibleSlides: 3,
              draggingDistance: 300,
            },
            800: {
              visibleSlides: 1,
              slideMultiple: 2,
              draggingDistance: 50,
            },
          }"
        >
          <!-- Arrow right -->
          <template #arrow-left>
            <svg
              width="40"
              height="12"
              viewBox="0 0 40 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3.16016"
                y="4.88174"
                width="36.8406"
                height="2.23629"
                rx="1.11815"
                fill="#D1D4D7"
              />
              <rect
                width="11.1682"
                height="2.23541"
                rx="1.1177"
                transform="matrix(0.865683 0.500593 -0.499408 0.866367 1.11621 4.47354)"
                fill="#D1D4D7"
              />
              <rect
                width="11.1682"
                height="2.23541"
                rx="1.1177"
                transform="matrix(0.865683 -0.500593 0.499408 0.866367 0 5.59073)"
                fill="#D1D4D7"
              />
            </svg>
          </template>

          <vueper-slide
            v-for="(slide, i) in testimonials"
            :arrows="true"
            :key="i + 'slide'"
            :content="'&#9473;' + slide.title"
            :title="slide.description"
          >
            <template #content>
              <div v-if="isImageModeEnabled" class="vueperslide__content-wrapper image-mode-wrapper">
                <img :src="slide.images[0]?.url" :alt="slide.title" class="slide-image" />
                <div class="slide-content-wrapper">
                  <div class="slide-text-wrapper">
                    <h6 class="slide-title">{{ slide.title }}</h6>
                    <p class="slide-subtitle">{{ slide.subtitle }}</p>
                  </div>
                  <a :href="slide.url" target="_blank">
                    <LinkedInIcon color="#1B1E21" class="linkedin-icon"/>
                  </a>
                </div>
              </div>
              <div v-else-if="isVideosModeEnabled" class="vueperslide__content-wrapper">
                <div class="video-mode-wrapper yt-embed-holder">
                  <iframe :src="`${slide.url + '?enablejsapi=1&modestbranding=1'}`">
                  </iframe>
                </div>
                <h6 class="yt-video-title">{{ slide.title }}</h6>
              </div>
              <div v-else class="vueperslide__content-wrapper">
                <div class="vueperslide__title" v-html="slide.description">
                </div>
                <div class="vueperslide__content">
                  <div class="store-icon-wrapper">
                    <component
                      :is="getIconComponent(slide.brand)"
                      class="store-icon"
                      aria-label="Store icon"
                    />
                  </div>
                  <div class="author-rating-wrapper">
                    <span class="author"> {{ slide.title }} </span>
                    <div class="rating">
                      <StarRatingIcon />
                      <StarRatingIcon />
                      <StarRatingIcon />
                      <StarRatingIcon />
                      <StarRatingIcon />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </vueper-slide>

          <!-- Arrow right -->
          <template #arrow-right>
            <svg
              width="40"
              height="12"
              viewBox="0 0 40 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="4.8837"
                width="36.8405"
                height="2.23629"
                rx="1.11815"
                fill="#D1D4D7"
              />
              <rect
                width="11.1682"
                height="2.23541"
                rx="1.1177"
                transform="matrix(0.865682 0.500594 -0.499406 0.866368 30.332 0.00088501)"
                fill="#D1D4D7"
              />
              <rect
                width="11.1682"
                height="2.23541"
                rx="1.1177"
                transform="matrix(0.865682 -0.500594 0.499406 0.866368 29.2148 10.0634)"
                fill="#D1D4D7"
              />
            </svg>
          </template>
        </vueper-slides>
      </div>
    </div>
    <EngagementSection v-if="engagementSectionEnabled" />
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import StarRatingIcon from '@/assets/icons/StarRatingIcon.vue';
import { STORE_ICONS } from '@/utils/constants';
import LinkedInIcon from '@/assets/icons/LinkedInIcon.vue';
import "vueperslides/dist/vueperslides.css"; // This imports vueper slides library CSS that is initally missing for some reason
import EngagementSection from './EngagementSection.vue';

const { t } = useTranslation(['shared']);

const props = defineProps({
  active: Number,
  title: String,
  testimonials: {
    type: Array,
    default: []
  },
  buttonText: String,
  buttonUrl: String,
  slideMode: {
    type: String,
    default: 'default'
  },
  darkModeEnabled: {
    type: Boolean,
    default: false
  },
  engagementSectionEnabled: {
    type: Boolean,
    default: false
  },
  showCounter: {
    type: Boolean,
    default: true
  }
});

const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);
const comment = ref(0);
const allComment = ref(0);

const isActive = computed(() => {
  if (isMobile.value) return props.active >= 6;
  return props.active === 6;
});

const isImageModeEnabled = computed(() => props.slideMode === 'images');
const isVideosModeEnabled = computed(() => props.slideMode === 'videos');

const getIconComponent = (brand) => {
  const DEFAULT_BRAND = 'APP_STORE';

  if (brand && STORE_ICONS[brand]) {
    return defineAsyncComponent(STORE_ICONS[brand]);
  }

  return defineAsyncComponent(STORE_ICONS[DEFAULT_BRAND]);
}

function countComment() {
  setTimeout(() => {
    const activeCommentElement = document.querySelector(".vueperslides__bullet--active span");
    const totalCommentsElement = document.querySelector(".vueperslides__bullets .vueperslides__bullet:last-child span");

    if (activeCommentElement && totalCommentsElement) {
      let activeComment = activeCommentElement.textContent;
      let totalComments = totalCommentsElement.textContent;

      comment.value = activeComment <= 9 ? `0${activeComment}` : activeComment;
      allComment.value = totalComments <= 9 ? `0${totalComments}` : totalComments;
    }
  }, 1000);
}

watch(props.testimonials, (newVal) => {
  if (newVal) {
    countComment();
  }
}, { deep: true });

onMounted(() => {
  countComment();
});
</script>
