import Prices from "@/services/prices.service";

export default {
  namespaced: true,
  state: {
    prices: null,
    vendors: [],
    bundleLink: ''
  },
  mutations: {
    prices(state, payload) {
      state.prices = payload;
    },
    SET_VENDORS(state, payload) {
      state.vendors = payload;
    },
    SET_BUNDLE_LINK(state, payload) {
      state.bundleLink = payload;
    }
  },
  actions: {
    getPrices({ commit, state}, countryCode) {
      return Prices.getPrices(countryCode).then(({ data }) => {
        commit("prices", data.countryByCountryCode.country);
        commit("SET_VENDORS", data.countryByCountryCode.country.vendors);

        const vendorWithBundleLink = state.vendors.find(vendor => vendor.bundleSiteUrl);

        if (vendorWithBundleLink) {
          commit("SET_BUNDLE_LINK", vendorWithBundleLink.bundleSiteUrl);
        }

        commit("prices", data.countryByCountryCode.country);
        commit("SET_VENDORS", data.countryByCountryCode.country.vendors);
      });
    },
  },
};
