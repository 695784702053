<template>
    <div v-if="showBanner" class="promo-banner">
        <div @click="handleContainerClick" class="banner-container">
            <div class="banner-image">
                <img
                    :src="bannerData?.image?.url"
                    :alt="bannerData?.image.imageMeta?.metaAltDescription"
                    :title="bannerData?.image.imageMeta?.metaTitle"
                    alt="Carista EVO"
                />
            </div>
            <div class="banner-info">
                <h2 class="banner-title">{{ bannerData.title }}</h2>
                <p class="banner-price">
                    <span class="price">{{
                        prices?.showDiscountedPrice
                            ? prices?.priceDiscounted
                            : prices?.price
                    }}</span>
                    <s
                        v-if="prices.showDiscountedPrice"
                        id="strike-through-price"
                        >{{ prices?.price }}</s
                    >
                </p>
                <button class="banner-button hide-on-mobile" @click="goToDeal">
                    {{ t('shared_get-now') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';
import { useVendorAction } from '@/composables/usePurchaseFlow';
import { facebookGoogleAnalitycs } from '@/utils/helpers';
import { SHOPIFY_BUNDLE_COUNTRIES } from '@/utils/constants';

const store = useStore();
const parser = new DOMParser();

const { t } = useTranslation(['shared']);

const { handleVendorAction } = useVendorAction();

const isMobile = computed(() => store.state.device.isMobile);
const countryCode = computed(() => store.state.prices?.prices?.countryCode);
const shouldShowBundleBanner = computed(() => SHOPIFY_BUNDLE_COUNTRIES.includes(countryCode.value));

const bannerData = computed(() => {
    const itemTag = shouldShowBundleBanner.value
        ? 'promo-banner-[shopify-countries]'
        : 'promo-banner-[rest-of-the-countries]';

    const item = store.state.pages.supported_cars?.items?.find(
        (item) => item.tag === itemTag
    );

    const content = item?.content?.content[0];

    const doc = parser.parseFromString(content, 'text/html');

    const text = doc.querySelectorAll('p');

    const textContent = isMobile.value
        ? text[1]?.textContent
        : text[0]?.textContent;

    const imageTag = `ad-banner-${
        shouldShowBundleBanner.value ? 'UK-US' : 'all-countries'
    }-photo-${isMobile.value ? 'mobile' : 'desktop'}`;

    const image = item?.images.find((img) => img?.tag == imageTag);

    return {
        title: textContent ?? '',
        image: image,
    };
});

const prices = computed(() => {
    const currency = store.state.prices?.prices?.currency;
    const evo = store.state.prices?.prices?.evoAdapterPrice;
    const evoDiscounted = store.state.campaign?.prices?.evo;

    const bundle = store.state.prices?.prices?.bundlePrice;
    const bundleDiscounted = store.state.campaign?.prices?.bundle;

    if (shouldShowBundleBanner.value) {
        return {
            price: `${currency}${bundle}`,
            priceDiscounted: `${currency}${bundleDiscounted}`,
            showDiscountedPrice: !!bundleDiscounted,
        };
    }

    return {
        price: `${currency}${evo}`,
        priceDiscounted: `${currency}${evoDiscounted}`,
        showDiscountedPrice: !!evoDiscounted,
    };
});

const showBanner = computed(
    () => bannerData.value.title && bannerData.value.image && prices.value.price
);

const handleContainerClick = () => {
    if (isMobile.value) {
        goToDeal();
    }

    return;
};

const goToDeal = () => {
    const eventName = isMobile.value
        ? 'evo_banner_vehicle_selector'
        : 'get_now_vehicle_selector';

    facebookGoogleAnalitycs(eventName);
    handleVendorAction();
};
</script>

<style lang="scss" scoped>
.promo-banner {
    display: flex;
    justify-content: center;
    margin-top: 29px;
}

.banner-container {
    display: flex;
    flex-direction: row;
    gap: 18px;
    width: 690px;
    margin-bottom: 24px;
    padding: 18px 18px 0px 18px;
    background-color: $color-neutral-pale;
    border: 1px solid $color-primary-default;
    border-radius: 24px;
}
.banner-image {
    width: 152px;

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.banner-info {
    display: flex;
    flex-direction: column;
}

.banner-title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.36px;
    color: $color-neutral-deep;
}

.banner-price {
    margin: 14px 0 16px;
    display: flex;
    align-items: center;
    .price {
        font-size: 20px;
    }

    #strike-through-price {
        color: $color-gray;
        font-size: 16px;
        margin-left: 10px;
    }
}

.banner-button {
    width: fit-content;
    padding: 14px 20px;
    border-radius: 34px;
    margin-bottom: 18px;
    background-color: $color-primary-default;

    font-size: 14px;
    font-weight: 500;
    color: $color-white;
}

.banner-button:hover {
    background-color: #0056b3;
}

@media (max-width: $screen-md) {
    .promo-banner {
        margin-top: 0;
    }

    .banner-container {
        width: 100%;
        max-height: 114px;
        margin: 0 24px 42px 24px;
        gap: 12px;
    }

    .banner-image {
        width: min-content;
        padding-bottom: 16px;

        img {
            width: auto;
            min-width: 86px;
            height: 100%;
            object-fit: contain;
        }
    }

    .banner-info {
        justify-content: center;
    }

    .banner-title {
        font-size: 20px;
    }

    .banner-price {
        margin: 6px 0 18px;
    }
}
</style>
