<template>
    <div class="ratings-section container">
        <div class="title">{{ t('shared_trusted-by-over-1M-users') }}</div>
        <div class="ratings-container">
            <a
                v-for="(rating, index) in ratings"
                :key="index + 'ratings'"
                :href="rating.link"
                :class="{ 'gray-mode': grayModeEnabled }"
                target="_blank"
            >
                <component :is="rating.iconComponent" class="icon" />
                <div class="rating-wrapper">
                    <div class="rating">{{ rating.score }}<StarIcon /></div>
                    {{ t(rating.textKey) }}
                </div>
            </a>
        </div>
    </div>
</template>

<script setup>
import { useTranslation } from 'i18next-vue';
import StarIcon from '@/assets/icons/StarIcon';
import AppleLogoIcon from '@/assets/icons/AppleLogoIcon';
import GooglePlayColorIcon from '@/assets/icons/GooglePlayColorIcon';
import AmazonIcon from '@/assets/icons/AmazonIcon';
import { STORE_LINKS } from '@/utils/constants';

const { t } = useTranslation(['shared']);

const props = defineProps({
    grayModeEnabled: {
        type: Boolean,
        default: false,
    },
});

const ratings = [
    {
        link: STORE_LINKS.APP_STORE,
        score: '4.7',
        iconComponent: AppleLogoIcon,
        textKey: 'App Store',
    },
    {
        link: STORE_LINKS.GOOGLE_PLAY,
        score: '4.6',
        iconComponent: GooglePlayColorIcon,
        textKey: 'Google Play',
    },
    {
        link: STORE_LINKS.AMAZON,
        score: '4.4',
        iconComponent: AmazonIcon,
        textKey: 'Amazon',
    },
];
</script>

<style lang="scss" scoped>
.ratings-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 150px;

    @media (max-width: $screen-md) {
        align-items: flex-start;
        padding-top: 30px;
    }

    .title {
        font-weight: 500;
        font-size: 40px;
        margin: 28px 0 42px 0;

        @media (max-width: $screen-md) {
            font-size: 32px;
            text-align: center;
        }
    }

    .ratings-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;

        @media (max-width: $screen-md) {
            flex-wrap: wrap;
            gap: 12px;
        }

        a {
            display: flex;
            align-items: center;
            gap: 24px;

            min-width: 190px;
            background-color: $color-neutral-lightest;
            border-radius: 18px;
            padding: 12px 18px;

            &.gray-mode {
                background-color: $color-white;
            }

            svg {
                height: 19px;
                width: 19px;
            }

            .rating-wrapper {
                color: $color-neutral-deep;
                font-size: 16px;
                font-weight: 500;

                .rating {
                    font-size: 24px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }
            }

            @media (max-width: $screen-md) {
                min-width: 100%;
                padding: 18px 24px;

                svg {
                    height: 28px;
                    width: 28px;
                }

                .rating-wrapper {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: $screen-md) {
        padding-bottom: 96px;
    }
}
</style>
