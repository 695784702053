<template>
  <div v-if="selectedLang?.code && languages.length > 0" class="language-selector hide-on-mobile">
    <button class="selected-language">
      {{ selectedLang.code }}
    </button>
    <ul class="language-list" :class="{ active: isOpenDropdown }">
      <li
        v-for="(lang, index) in languages"
        @click="langChanged(lang)"
        :key="lang?.code + index"
        :class="{ selected: lang.code === selectedLang.code }"
      >
        <div>
          {{ lang?.code }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const isOpenDropdown = ref(false);

const selectedLang = computed(() => store.state.language.lang);
const languages = computed(() => store.state.language.all);

function langChanged(lang) {
  isOpenDropdown.value = false;
  store.dispatch("language/changeLanguage", lang);
  store.dispatch("profile/setPreferredLanguage", lang.code);
}
</script>
