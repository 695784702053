<template>
    <div class="embark">
        <div class="container">
            <div class="content">
                <h4
                    v-if="titleFormatted"
                    v-html="titleFormatted"
                    class="title"
                ></h4>
                <GetStarted
                    :title="defaultButtonTitle"
                    :subtitle="defaultButtonSubTitle"
                    :fbEventParam="fbEventParam"
                    :fbEvent="fbEvent"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import GetStarted from '@/components/GetStarted.vue';
import { SHOPIFY_BUNDLE_COUNTRIES } from '@/utils/constants';
import { useRoute } from 'vue-router';

const props = defineProps({
    title: {
        type: String,
    },
    buttonTitle: {
        type: String,
    },
    buttonSubTitle: {
        type: String,
    },
    to: [String, Object],
    fbEvent: String,
    fbEventParam: String,
});

const route = useRoute();
const store = useStore();
const parser = new DOMParser();

const countryCode = computed(() => store.state.prices?.prices?.countryCode);
const shouldShowBundleContent = computed(() =>
    SHOPIFY_BUNDLE_COUNTRIES.includes(countryCode.value)
);

const content = computed(() => {
    const allCountriesItem = store.state.pages.homepage?.items?.find(
        (item) => item.tag == 'call-to-action-section-[rest-of-the-countries]'
    );

    const ukUsItem = store.state.pages.homepage?.items?.find(
        (item) => item.tag == 'call-to-action-section-[shopify-countries]'
    );

    let content = allCountriesItem?.content?.content[0];

    if (shouldShowBundleContent.value) {
        content = ukUsItem?.content?.content[0];
    }

    const doc = parser.parseFromString(content, 'text/html');

    const mainTitle = doc.querySelector('h4');
    const buttonTitle = doc.querySelector('h6');
    const buttonSubtitle = doc.querySelector('p');

    return {
        mainTitle: mainTitle?.innerHTML,
        buttonTitle: buttonTitle?.textContent,
        buttonSubtitle: buttonSubtitle?.textContent,
    };
});

const gradientSpanStyle = computed(() => {
    if (!props.title || !props.title.length) {
        return {};
    }

    const shouldDisplayInline =
        props.title[1]?.toLowerCase() === 'free' ||
        props.title[1]?.toLowerCase() === 'evolve';

    return { display: shouldDisplayInline ? 'inline' : 'block' };
});

const titleFormatted = computed(() => {
    if (props.title) {
        return props.title;
    }

    return content.value.mainTitle;
});

const defaultButtonTitle = computed(() => {
    if (props.buttonTitle) {
        return props.buttonTitle;
    }

    return content.value.buttonTitle;
});
const defaultButtonSubTitle = computed(() => {
    if (props.buttonSubTitle) {
        return props.buttonSubTitle;
    }

    return content.value.buttonSubtitle;
});

onBeforeMount(() => {
    if (route.name !== 'home' && !store.state.pages.homepage) {
        store.dispatch('pages/getPageByTag', { tag: 'homepage' });
    }
});
</script>

<style lang="scss" scoped>
.title {
    font-size: 56px;
    font-weight: 500;
    letter-spacing: -0.56px;
}
</style>
