// composables/useVendorAction.js
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ADAPTER_TYPE, DEFAULT_COUNTRY_CODE } from '@/utils/constants'; // Import constants if needed
import { facebookGoogleAnalitycs } from '@/utils/helpers';

export function useVendorAction() {
	const store = useStore();
	const router = useRouter();

	const prices = computed(() => store.state.prices?.prices);
	const vendors = computed(() => store.state.prices?.vendors);
	const bundleLink = computed(() => store.state.prices?.bundleLink);

	const isSingleVendor = computed(() => {
		return vendors.value?.length === 1;
	});

	const singleVendorUrl = computed(() => {
		if (isSingleVendor.value) {
			return vendors.value[0]?.evoSiteUrl;
		}
		return null;
	});

	const redirectToVendor = (url) => {
		const shouldOpenNewTab = !/shop.+carista.com|2ly.link./.test(url);
		window.open(url, shouldOpenNewTab ? '_blank' : '_self');
	};

	const showModal = (adapterType) => {
		store.dispatch('modals/openModal', {
			currentModal: 'isVendorModalOpen',
			data: adapterType || true,
		});
	};

	const handleVendorAction = (eventName, adapterType = ADAPTER_TYPE.EVO) => {
		// If there is a Bundle Link
		if (bundleLink.value && isSingleVendor.value) {
			redirectToVendor(bundleLink.value);
			return;
		};

		if (isSingleVendor.value) {
			redirectToVendor(singleVendorUrl.value);
			return;
		}

		showModal(adapterType);
	};

	return {
		handleVendorAction,
		isSingleVendor,
		singleVendorUrl,
		prices,
	};
}
