class Icons {
  constructor() {
    this.icons = {
      //Supported cars Result page CUSTOMIZE ICONS
      "Most Popular": () => import("@/assets/icons/supportedCars/CupMostPopularIcon.vue"),
      "Dings & Warnings":  () => import("@/assets/icons/supportedCars/DingsIcon.vue"),
      Mirrors:  () => import("@/assets/icons/supportedCars/MirrorsIcon.vue"),
      "Parking Sensors":  () => import("@/assets/icons/supportedCars/ParkingIcon.vue"),
      "Instruments: Displays & Nav":  () => import("@/assets/icons/supportedCars/DisplayNavIcon.vue"),
      "Chassis & Engine":  () => import("@/assets/icons/supportedCars/EngineIcon.vue"),
      "Lights: Exterior":  () => import("@/assets/icons/supportedCars/LightsExteriorIcon.vue"),
      "Lights: Daytime Running Lights (DRL)":  () => import("@/assets/icons/supportedCars/LightsExteriorIcon.vue"),
      "Lights: Interior":  () => import("@/assets/icons/supportedCars/LightsExteriorIcon.vue"),
      "Locking: Beep & Blink":  () => import("@/assets/icons/supportedCars/LockingIcon.vue"),
      "Locking: Doors & Alarm":  () => import("@/assets/icons/supportedCars/LockingIcon.vue"),
      "Windows & Sunroof":  () => import("@/assets/icons/supportedCars/SunRoofIcon.vue"),
      "Lights: Automatic":  () => import("@/assets/icons/supportedCars/LightsExteriorIcon.vue"),
      "Locking: Automatic":  () => import("@/assets/icons/supportedCars/LockingIcon.vue"),
      "Locking: Smart Key":  () => import("@/assets/icons/supportedCars/LockingIcon.vue"),
      "Seats & Steering Wheel":  () => import("@/assets/icons/supportedCars/SeatsIcon.vue"),
      Trunk:  () => import("@/assets/icons/supportedCars/TrunkIcon.vue"),
      "Lights: Bulb Checks":  () => import("@/assets/icons/supportedCars/LightsExteriorIcon.vue"),
      "Lights: Coming/Leaving Home":  () => import("@/assets/icons/supportedCars/LightsExteriorIcon.vue"),
      "Heater & A/C":  () => import("@/assets/icons/supportedCars/AcIcon.vue"),
      "Wipers & Washer":  () => import("@/assets/icons/supportedCars/WipersIcon.vue"),
      Other:  () => import("@/assets/icons/supportedCars/OthersIcon.vue"),
      "Driver Assist":  () => import("@/assets/icons/supportedCars/DriverIcon.vue"),
      "Instruments: Language & Units":  () => import("@/assets/icons/supportedCars/DisplayNavIcon.vue"),
      "Audi Drive Select (ADS)":  () => import("@/assets/icons/supportedCars/OthersIcon.vue"),
      "Basic OBD2":  () => import("@/assets/icons/supportedCars/OthersIcon.vue"),

      // Supported brands logos
      "AudiLogo":  () => import("@/assets/icons/car-logos/AudiLogo.vue"),
      "BMWLogo":  () => import("@/assets/icons/car-logos/BMWLogo.vue"),
      "CupraLogo":  () => import("@/assets/icons/car-logos/CupraLogo.vue"),
      "FordLogo":  () => import("@/assets/icons/car-logos/FordLogo.vue"),
      "InfinitiLogo":  () => import("@/assets/icons/car-logos/InfinitiLogo.vue"),
      "JaguarLogo":  () => import("@/assets/icons/car-logos/JaguarLogo.vue"),
      "Land RoverLogo":  () => import("@/assets/icons/car-logos/LandRoverLogo.vue"),
      "LexusLogo":  () => import("@/assets/icons/car-logos/LexusLogo.vue"),
      "LincolnLogo":  () => import("@/assets/icons/car-logos/LincolnLogo.vue"),
      "MazdaLogo":  () => import("@/assets/icons/car-logos/MazdaLogo.vue"),
      "MiniLogo":  () => import("@/assets/icons/car-logos/MiniLogo.vue"),
      "NissanLogo":  () => import("@/assets/icons/car-logos/NissanLogo.vue"),
      "ScionLogo":  () => import("@/assets/icons/car-logos/ScionLogo.vue"),
      "SEATLogo":  () => import("@/assets/icons/car-logos/SEATLogo.vue"),
      "SkodaLogo":  () => import("@/assets/icons/car-logos/SkodaLogo.vue"),
      "ToyotaLogo":  () => import("@/assets/icons/car-logos/ToyotaLogo.vue"),
      "VolkswagenLogo":  () => import("@/assets/icons/car-logos/VolkswagenLogo.vue"),
      "VolvoLogo":  () => import("@/assets/icons/car-logos/VolvoLogo.vue"),

      basic:  () => import("@/assets/icons/supportedCars/OthersIcon.vue"),
    };
  }

  getIcon(iconKey) {
    if (this.icons[iconKey]) {
      return this.icons[iconKey];
    } else {
      return this.icons["basic"];
    }
  }
}

export default new Icons();
