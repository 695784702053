<template>
  <div
    class="how-it-works"
    :class="{ 'white-background': isStatic }"
    id="howItWorksSection"
  >
    <div class="container" :class="{ sticky: isActive, animated: isAnimated }">
      <div class="content">
        <div class="left">
          <h2 class="title" :class="{ active: isActive }">
            {{ howItWorks?.headings?.h2[0] }}
          </h2>
          <ul class="steps-list">
            <li :class="{ 'active-step': activeStep === 1 }">
              <p>{{ t("shared_step") }} 01</p>
              <a @click.prevent="$emit('changeActiveStep', 1)" href="#">
                {{ t("shared_step-1-content") }}
              </a>
            </li>
            <li :class="{ 'active-step': activeStep === 2 }">
              <p>{{ t("shared_step") }} 02</p>
              <a @click.prevent="$emit('changeActiveStep', 2)" href="#">
                {{ t("shared_step-2-content") }}
              </a>
            </li>
            <li :class="{ 'active-step': activeStep === 3 }">
              <p>{{ t("shared_step") }} 03</p>
              <a @click.prevent="$emit('changeActiveStep', 3)" href="#">
                {{ t("shared_step-3-content") }}
              </a>
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="images-wrapper">
            <img
              :class="{ 'active-step': activeStep === 1 }"
              :src="howItWorks?.children[0]?.images[0]?.url"
              :alt="howItWorks?.children[0]?.images[0]?.imageMeta?.metaAltDescription"
              :title="howItWorks?.children[0]?.images[0]?.imageMeta?.metaTitle"
              loading="lazy"
            />
            <img
              :class="{ 'active-step': activeStep === 2 }"
              :src="howItWorks?.children[1]?.images[0]?.url"
              :alt="howItWorks?.children[1]?.images[0]?.imageMeta?.metaAltDescription"
              :title="howItWorks?.children[1]?.images[0]?.imageMeta?.metaTitle"
              loading="lazy"
            />
            <img
              :class="{ 'active-step': activeStep === 3 }"
              :src="howItWorks?.children[2]?.images[0]?.url"
              :alt="howItWorks?.children[2]?.images[0]?.imageMeta?.metaAltDescription"
              :title="howItWorks?.children[2]?.images[0]?.imageMeta?.metaTitle"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div class="content-mobile">
        <p class="title" :class="{ active: isActive }">
          {{ t("shared_how-it-works") }}
        </p>
        <div class="steps__navigation">
          <span
            v-for="(step, index) in steps"
            :key="'text' + '-' + step"
            @click="activeMobileStep = index"
            class="step"
            :class="{
              'active-step': activeMobileStep === index,
              active: isActive,
            }"
            >{{ t("shared_step") }} {{ step }}</span
          >
        </div>
        <div class="steps__wrapper">
          <p class="content-description" :class="{ active: isActive }">
            <span v-show="activeMobileStep === 0">{{
              t("shared_step-1-content")
            }}</span>
            <span v-show="activeMobileStep === 1">{{
              t("shared_step-2-content")
            }}</span>
            <span v-show="activeMobileStep === 2">{{
              t("shared_step-3-content")
            }}</span>
          </p>
          <div class="buttons-holder">
            <button
              v-for="(step, index) in steps"
              :key="'button' + '-' + index"
              @click="activeMobileStep = index"
              :class="{ 'active-step': activeMobileStep === index }"
            ></button>
          </div>
          <div class="images-holder" @touchstart="touchStart">
            <img
              id="step-img1"
              class="static"
              src="/images/step1.jpg"
              alt="step 1"
              loading="lazy"
            />
            <img
              id="step-img1"
              class="changeable"
              :class="{ 'active-step': activeMobileStep === 0, 'reverse-transition': activeMobileStep >= 1 }"
              :src="howItWorks?.children[0]?.images[0]?.url"
              :alt="howItWorks?.children[0]?.images[0]?.imageMeta?.metaAltDescription"
              :title="howItWorks?.children[0]?.images[0]?.imageMeta?.metaTitle"
              loading="lazy"
            />
            <img
              id="step-img2"
              class="changeable"
              :class="{ 'active-step': activeMobileStep === 1, 'reverse-transition': activeMobileStep === 2 }"
              :src="howItWorks?.children[1]?.images[0]?.url"
              :alt="howItWorks?.children[1]?.images[0]?.imageMeta?.metaAltDescription"
              :title="howItWorks?.children[1]?.images[0]?.imageMeta?.metaTitle"
              loading="lazy"
            />
            <img
              id="step-img3"
              class="changeable"
              :class="{ 'active-step': activeMobileStep === 2 }"
              :src="howItWorks?.children[2]?.images[0]?.url"
              :alt="howItWorks?.children[2]?.images[0]?.imageMeta?.metaAltDescription"
              :title="howItWorks?.children[2]?.images[0]?.imageMeta?.metaTitle"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";

const { t } = useTranslation(['shared']);

const props = defineProps({
  active: Number,
  activeStep: Number,
  static: Boolean,
});

defineEmits(['changeActiveStep']);

const store = useStore();

const activeMobileStep = ref(0);
const steps = ref(["01", "02", "03"]);

const howItWorks = computed(() => store.state.pages.how_it_works);
const isActive = computed(() => props.active === 5);
const isAnimated = computed(() => props.active > 5);
const isStatic = computed(() => props.static);

function touchStart(touchEvent) {
  // prevent swiping with more than one finger
  if (touchEvent.changedTouches.length !== 1) {
    return;
  }
  const posXStart = touchEvent.changedTouches[0].clientX;
  addEventListener(
    "touchend",
    (touchEvent) => touchEnd(touchEvent, posXStart),
    { once: true }
  );
}

function touchEnd(touchEvent, posXStart) {
  if (touchEvent.changedTouches.length !== 1) {
    return;
  }
  const posXEnd = touchEvent.changedTouches[0].clientX;
  if (Math.abs(posXStart - posXEnd) > 80) {
    if (posXStart < posXEnd) {
      activeMobileStep.value === 0
        ? activeMobileStep.value = 2
        : activeMobileStep.value--; // swipe right
    } else if (posXStart > posXEnd) {
      activeMobileStep.value === 2
        ? activeMobileStep.value = 0
        : activeMobileStep.value++; // swipe left
    }
  }
}

onBeforeMount(async () => {
  if (!store.state.pages.how_it_works) {
    await store.dispatch('pages/getPageByTag', { tag: 'how_it_works' });
  }
});

</script>

<style lang="scss" scoped>

.content-description, .step {
  @media (max-width: $screen-md) {
    color: $color-neutral-deep !important;
  }
}

.reverse-transition {
  transform: translateX(-200%) !important;
}

</style>
