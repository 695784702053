<template>
  <section class="container">
    <div class="homepage__section homepage__intro">
      <div class="content">
        <h1 :class="{ 'title-animate': isActive, show: show }">
          {{ h1text }}
        </h1>
        <h2 :class="{ sub_title_animate: isActive }">
          {{ h2text }}
        </h2>
        <div class="homepage__intro__row">
          <div class="left">
            <div
              class="animations-phone"
              :class="{ animate: isActive, 'move-top': MoveUp }"
            >
              <img
                :alt="imagePhoneAlt"
                :src="imagePhone"
                :title="imagePhoneTitle"
                loading="lazy"
              />
            </div>
            <div
              class="aminations-adapter"
              :class="{ animate: isActive, 'move-top': MoveUp }"
            >
              <img
                :alt="imageAdapterAlt"
                :src="imageAdapter"
                :title="imageAdapterTitle"
                loading="lazy"
              />
            </div>
          </div>
          <div
            class="embark"
            :class="{ animate: isActive, 'move-top': MoveUp }"
          >
            <GetStarted
              :title="content?.buttonTitle"
              :subtitle="content?.buttonSubtitle"
              className="white"
              fbEventParam="home page up"
              fbEvent="get_started_click"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import GetStarted from '../GetStarted.vue';
import { SHOPIFY_BUNDLE_COUNTRIES } from '@/utils/constants';

const { t } = useTranslation(['page-home', 'shared']);
const parser = new DOMParser();

const props = defineProps({
  active: Number,
  imagePhone: String,
  imageAdapter: String,
  imagePhoneAlt: String,
  imagePhoneTitle: String,
  imageAdapterAlt: String,
  imageAdapterTitle: String,
  show: Boolean,
  h1text: String,
  h2text: String,
});

const store = useStore();

const langCode = computed(() => store.state.language.lang?.code);

const isActive = computed(() => props.active > 0);
const MoveUp = computed(() => props.active > 1);

const countryCode = computed(() => store.state.prices?.prices?.countryCode);
const shouldShowBundleContent = computed(() => SHOPIFY_BUNDLE_COUNTRIES.includes(countryCode.value));

const content = computed(() => {
    const allCountriesItem = store.state.pages.homepage?.items?.find(
        (item) => item.tag == 'hero-button-[rest-of-the-countries]'
    );

    const ukUsItem = store.state.pages.homepage?.items?.find(
        (item) => item.tag == 'hero-button-[shopify-countries]'
    );

    let content = allCountriesItem?.content?.content[0];

    if (shouldShowBundleContent.value) {
        content = ukUsItem?.content?.content[0];
    }

    const doc = parser.parseFromString(content, 'text/html');

    const buttonTitle = doc.querySelector('h6');
    const buttonSubtitle = doc.querySelector('p');

    return {
        buttonTitle: buttonTitle?.textContent,
        buttonSubtitle: buttonSubtitle?.textContent,
    };
});
</script>
