<template>
    <div class="sale-banner">
        <a
            :href="url"
            :data-is-clickable="url ? true : false"
            class="marquee"
            :class="[gradientClass]"
            target="_self"
            @click="
                facebookGoogleAnalitycs('ribbon_click', {
                    promotion_name: title || tag,
                })
            "
        >
            <div
                :class="{ 'enable-animation': isBarAnimated }"
                class="marquee-content"
            >
                <SaleTag
                    v-if="tag"
                    :text="tag"
                    :borderRadius="6"
                    :reverseColors="true"
                />
                <div v-if="title">{{ title }}</div>
            </div>
            <div
                :class="{ 'enable-animation': isBarAnimated }"
                class="marquee-content"
            >
                <SaleTag
                    v-if="tag"
                    :text="tag"
                    :borderRadius="6"
                    :reverseColors="true"
                />
                <div v-if="title">{{ title }}</div>
            </div>
        </a>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { facebookGoogleAnalitycs, isValidUrl } from '@/utils/helpers';
import SaleTag from './SaleTag.vue';

const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);
const title = computed(() => {
    if (isMobile.value) {
        return store.state.campaign?.bar?.textMobile;
    }

    return store.state.campaign?.bar?.text;
});

const tag = computed(() => {
    if (isMobile.value) {
        return store.state.campaign?.bar?.tagMobile;
    }

    return store.state.campaign?.bar?.tag;
});

const url = computed(() => {
    const url = store.state.campaign?.bar?.url;
    return isValidUrl(url) ? url : null;
});

const isBarAnimated = computed(() => store.state.campaign?.bar?.isAnimated);

const gradientClass = computed(() => {
    const background = store.state.campaign?.meta?.backgroundColor;

    switch (background) {
        case 'Sale':
            return 'bg-sale-gradient';
        case 'Main':
            return 'bg-main-gradient';
        case 'Feature':
            return 'bg-feature-gradient';
    }

    return '';
});
</script>

<style lang="scss" scoped>
a {
    padding-left: 0px;
    padding-right: 0px;
}

.marquee {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end !important;
    overflow: hidden;
    --gap: 1rem;
}

.marquee-content {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    min-width: 100%;
    align-items: center;
    gap: var(--gap);
}

.marquee-content.enable-animation {
    -webkit-animation: scroll 20s linear infinite;
    animation: scroll 20s linear infinite;

    @media (max-width: $screen-xxl) {
        -webkit-animation: scroll 16s linear infinite;
        animation: scroll 16s linear infinite;
    }

    @media (max-width: $screen-lg) {
        -webkit-animation: scroll 12s linear infinite;
        animation: scroll 12s linear infinite;
    }

    @media (max-width: $screen-md) {
        -webkit-animation: scroll 8s linear infinite;
        animation: scroll 8s linear infinite;
    }

    @media (max-width: $screen-xs) {
        -webkit-animation: scroll 5s linear infinite;
        animation: scroll 5s linear infinite;
    }
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(100% + var(--gap)));
    }
}
</style>
