import { GET_CAMPAIGN_BY_COUNTRY_CODE } from '@/utils/queries';
import i18next from 'i18next';
import axios from 'axios';
import { mapLangCodeToFullName } from '@/utils/helpers';
import appConfig from '@/config/app.config';
import campaignDataMocked from '@/assets/mock-data/campaignDataMocked.json';

const state = () => ({
    ribbon: null,
    popUp: null,
    prices: null,
    subscriptions: null,
    tags: null,
    meta: null,
    campaign: null,
    images: [],
    pages: []
});

const mutations = {
    SET_CAMPAIGN_DATA(state, data) {
        // Ribbon data
        state.bar = {
            text: data.ribbonDesktop,
            textMobile: data.ribbonMobile,
            tag: data.ribbonTagDesktop,
            tagMobile: data.ribbonTagMobile,
            url: data.ribbonUrl,
            isAnimated: data.ribbonAnimation,
        };

        // Pop up date
        state.popUp = {
            title: data.popUpTitleDesktop,
            titleMobile: data.popUpTitleMobile,
            bodyText: data.popUpBodyTextDesktop,
            bodyTextMobile: data.popUpBodyTextMobile,
            tag: data.popUpTagDesktop,
            tagMobile: data.popUpTagMobile,
            button: data.popUpButtonDesktop,
            buttonMobile: data.popUpButtonMobile,
            buttonUrl: data.popUpButtonUrl,
        };

        // Tag data
        state.tags = {
            footerScanners: data.footerTag,
            footerApp: data.footerAppTag,
            headerScanners: data.menuTag,
            headerApp: data.menuAppTag,
            headerAndFooterUrl: data.menuTagAndFooterTagUrl,
            adaptersPageEvo: data.adaptersPageEvoTag,
            adaptersPageEvoUrl: data.adaptersPageEvoTagUrl,
            adaptersPageObd: data.adaptersPageCaristaObdTag,
            adaptersPageObdUrl: data.adaptersPageCaristaObdTagUrl,
            appAndPricingEvo: data.appAndPricingPagesEvoTag,
            appAndPricingObd: data.appAndPricingPagesCaristaObdTag,
            appAndPricingEvoUrl: data.appAndPricingPagesEvoTagUrl,
            appAndPricingObdUrl: data.appAndPricingPagesCaristaObdTagUrl,
        };

        // Prices data
        state.prices = {
            evo: data.promoPriceEvo,
            obd: data.promoPriceCaristaObd,
            bundle: data.promoPriceBundle
        };

        // Pricing plans
        state.subscriptions = {
            oneMonth: {
                text: data.appPricingPlans1MonthTag,
                price: data.appPricingPlans1MonthPrice
            },
            threeMonth: {
                text: data.appPricingPlans3MonthTag,
                price: data.appPricingPlans3MonthPrice
            },
            twelveMonth: {
                text: data.appPricingPlans12MonthTag,
                price: data.appPricingPlans12MonthPrice
            }
        }

        // Images
        state.images = data.images

        // Pages
        state.pages = data.pages

        // Meta data
        state.meta = {
            active: data.active,
            timeRemaining: data.remainingTime,
            secondsRemaining: data.remainingTimeSeconds,
            campaignEnd: data.endTime,
            backgroundColor: data.gradient
        };

        // All at once
        state.campaign = data;
    },
};

const actions = {
    async fetchCampaign({ commit, rootState: { geoIP } }) {
        try {
            const isDevEnv = process.env.NODE_ENV === 'development';

            if (isDevEnv && appConfig.USE_CAMPAIGN_MOCKED_DATA) {
                commit('SET_CAMPAIGN_DATA', campaignDataMocked.data.campaignByCountryCode.campaign);
                console.log('Campaign Mocked Successfully!');
                return;
            }

            const currentLanguage = mapLangCodeToFullName(i18next.language);
            const country = geoIP.geoIP.countryCode;

            const { data } = await axios.post(
                `${process.env.VUE_APP_WEB_CMS}`,
                {
                    query: GET_CAMPAIGN_BY_COUNTRY_CODE(
                        country,
                        currentLanguage,
                        process.env.VUE_APP_WEB_CMS_ENV
                    ),
                }
            );

            const campaignData = data.data.campaignByCountryCode.campaign;

            if (!campaignData) {
                return;
            }

            commit(
                'SET_CAMPAIGN_DATA',
                campaignData
            );
        } catch (error) {
            console.error('Error fetching campaign data:', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
