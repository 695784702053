<template>
    <div class="about-us-container">
        <BreadCrumb class="text-white" />
        <Hero :content="HeroData" />
        <ContentMedia :content="contentMediaData" />
        <VueperSlider
            :title="teamMembersData?.title"
            :testimonials="teamMembersData?.slideshow"
            :buttonText="teamMembersData?.buttonText"
            :buttonUrl="teamMembersData?.buttonUrl"
            :showCounter="false"
            slideMode="images"
            :active="6"
        />
        <ValueHighlights :content="valueHighlightsData" />
        <Showcase :content="showcaseData" />
        <div class="slideshow-and-ratings-container">
            <VueperSlider
                :title="t('shared_what-users-say-about-carista')"
                :testimonials="aboutUsTestimonials"
            />
            <div class="user-ratings-wrapper">
                <UserRatings />
            </div>
        </div>
        <Gallery :images="galleryImages" />
        <VueperSlider
            :title="videosData?.title"
            :testimonials="videosData?.slideshow"
            :darkModeEnabled="true"
            :engagementSectionEnabled="true"
            :showCounter="false"
            slideMode="videos"
            :active="6"
        />
        <PromoSection fbEventParam="about us page" />
    </div>
</template>

<script setup>
import { computed, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import BreadCrumb from '@/components/breadcrumb/BreadCrumb';
import Hero from '@/components/Hero.vue';
import ContentMedia from '@/components/ContentMedia.vue';
import ValueHighlights from '@/components/ValueHighlights.vue';
import VueperSlider from '@/components/VueperSlider';
import UserRatings from '@/components/UserRatings.vue';
import Gallery from '@/components/Gallery.vue';
import PromoSection from '@/components/PromoSection.vue';
import Showcase from '@/components/Showcase.vue';
import { useHead } from '@unhead/vue';
import { useTranslation } from 'i18next-vue';
import { formatPhraseWithHtml, createMetaInfo } from '@/utils/helpers';
import { TESTIMONIALS_CATEGORY_ID } from '@/utils/constants';

const store = useStore();
const parser = new DOMParser();
const { t } = useTranslation(['page-about-us', 'shared']);

const langCode = computed(() => store.state.language.lang?.code);

const HeroData = computed(() => {
    const item = store.state.pages.about_us?.items?.find(
        (item) => item.title == 'Hero Section'
    );
    const image = item?.images.find(
        (img) => img?.tag == 'carista-team-group-photo'
    );
    const content = item?.content?.content[0];

    const doc = parser.parseFromString(content, 'text/html');

    const h1title = doc.querySelector('h1');
    const paragraphs = doc.querySelectorAll('p');

    const h1 = h1title ? h1title.textContent : '';
    const p1 = paragraphs[0] ? paragraphs[0].textContent : '';
    const p2 = paragraphs[1] ? paragraphs[1].textContent : '';

    return {
        h1,
        p1,
        p2,
        image,
    };
});

const contentMediaData = computed(() => {
    const item = store.state.pages.about_us?.items?.find(
        (item) => item.title == 'FAQ, Content & Media Section'
    );
    const image = item?.images.find(
        (img) => img?.tag == 'carista-team-todor-kalaydjiev'
    );
    const content = item?.content?.content[0];

    const doc = parser.parseFromString(content, 'text/html');

    const h2Title = doc.querySelector('h2');
    const h6Title = doc.querySelector('h6');
    const preText = doc.querySelector('pre');
    const paragraphHTML = doc.querySelectorAll('p');
    const url = doc.querySelector('a');

    return {
        h2: h2Title?.textContent ?? '',
        h6: h6Title?.textContent ?? '',
        preText: preText?.textContent ?? '',
        p1: paragraphHTML[0]?.innerHTML ?? '',
        p2: paragraphHTML[1]?.innerHTML ?? '',
        p3: paragraphHTML[2]?.innerHTML ?? '',
        url: url?.href ?? '',
        image,
    };
});

const teamMembersData = computed(() => {
    const slideshow = store.state.pages.about_us?.slideshows?.find(
        (slideshow) => slideshow?.tag == 'team-members'
    );

    const item = store.state.pages.about_us?.items?.find(
        (item) => item.title == 'Team Members Section'
    );

    const content = item?.content?.content[0];

    const doc = parser.parseFromString(content, 'text/html');

    const h2Title = doc.querySelector('h2');
    const text = doc.querySelector('p');
    const url = doc.querySelector('a');

    return {
        title: h2Title?.textContent,
        buttonText: text?.textContent,
        buttonUrl: url?.href,
        slideshow: slideshow?.slideshowFrames || [],
    };
});

const valueHighlightsData = computed(() => {
    const item = store.state.pages.about_us?.items?.find(
        (item) => item.title == 'Value Highlights Section'
    );

    const content = item?.content?.content[0];

    const doc = parser.parseFromString(content, 'text/html');
    const headings = doc.querySelectorAll('h3');
    const contentArray = [];

    headings.forEach((heading) => {
        const paragraph = heading.nextElementSibling;

        if (paragraph && paragraph.tagName.toLowerCase() === 'p') {
            contentArray.push({
                title: heading.textContent.trim(),
                text: paragraph.textContent.trim(),
            });
        }
    });

    const gradientClass = 'text-gradient';

    const title = formatPhraseWithHtml(
        t('page-about-us_we-make-diagnostics-easy'),
        t('page-about-us_we-make-diagnostics-easy-gradient'),
        'span',
        gradientClass
    );

    return {
        title: title,
        items: contentArray,
    };
});

const galleryImages = computed(() => {
    const item = store.state.pages.about_us?.items?.find(
        (item) => item.title == 'Gallery Section'
    );

    return item?.images || [];
});

const videosData = computed(() => {
    const videos = store.state.pages.about_us?.slideshows?.find(
        (slideshow) => slideshow?.tag == 'videos'
    );

    return {
        title: t('page-about-us_how-we-roll-carista-in-action'),
        slideshow: videos?.slideshowFrames,
    };
});

const showcaseData = computed(() => {
    const item = store.state.pages.about_us?.items?.find(
        (item) => item.title == 'Product Showcase Section'
    );

    const content = item?.content?.content[0];

    const doc = parser.parseFromString(content, 'text/html');

    const h2Title = doc.querySelector('h2');
    const cardTitles = doc.querySelectorAll('h4');

    const phonesImage = item?.images.find((img) => img?.tag == 'two-phones');

    const adapterImage = item?.images.find(
        (img) => img?.tag == 'Carista EVO Scanner About us'
    );

    return {
        title: h2Title?.textContent,
        productOne: {
            title: cardTitles[0]?.textContent,
            image: phonesImage,
        },
        productTwo: {
            title: cardTitles[1]?.textContent,
            image: adapterImage,
        },
    };
});

const aboutUsTestimonials = computed(() => {
    return store.state.testimonials?.testimonials[
        TESTIMONIALS_CATEGORY_ID.ABOUT_US_PAGE - 1
    ];
});

watch(langCode, (newVal) => {
    store.dispatch('testimonials/getTestimonials', {
        lang: newVal,
        categoryId: TESTIMONIALS_CATEGORY_ID.ABOUT_US_PAGE,
    });
});

useHead(() => {
    const pageData = store.state.pages.about_us;

    return createMetaInfo({
        title: pageData?.metaTitle,
        pageMeta: pageData,
    });
});

onBeforeMount(() => {
    if (!store.state.pages.about_us) {
        store.dispatch('pages/getPageByTag', { tag: 'about_us' });
    }

    store.dispatch('testimonials/getTestimonials', {
        lang: langCode.value,
        categoryId: TESTIMONIALS_CATEGORY_ID.ABOUT_US_PAGE,
    });
});
</script>

<style lang="scss">
.slideshow-and-ratings-container {
    background: linear-gradient(257deg, #ebecee 11.32%, #fff 59.57%);
    background-color: #fff;

    .slider {
        padding-bottom: 128px;
    }

    .user-ratings-wrapper {
        padding: 78px 0px 28px;
        border-top: 1px solid $color-light;

        @media (max-width: $screen-md) {
            padding-top: 28px;
        }
    }
}

h2 {
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.56px;

    @media (max-width: $screen-md) {
        font-size: 36px;
        letter-spacing: -0.36px;
    }
}

h3 {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.4px;
}

h6 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.05px;
}

p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: $screen-md) {
        font-size: 18px;
    }
}
</style>
