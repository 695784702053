<template>
  <div
    v-if="pricing && prices && prices.appPricePerMonth"
    class="app-pricing"
  >
    <div class="container">
      <h2 class="main-title" v-if="$route.name === 'adapters'">
        {{ pricing?.headings?.h2[1] }}
      </h2>
      <h3 class="main-subtitle" v-if="$route.name === 'adapters'">
        {{ pricing?.headings?.h3[5] }}
      </h3>
      <div class="content">
        <div class="content-item">
          <p
            class="title"
          >
            {{ t("shared_get-carista-app-gradient-1") }}
            <span
              class="text-gradient"
            >{{ t("shared_get-carista-app-gradient-2") }}</span>{{ " " }}
            <span>{{ t("shared_get-carista-app-gradient-3") }}</span>
          </p>
          <p
            class="subtitle"
          >
            {{ t("shared_you-need-both-to-start-upgrading-your-car-experience") }}
          </p>
          <div class="text-holder">
            <div class="text-item">
              <SaleTag v-if="campaignData?.oneMonth?.text" :text="campaignData.oneMonth.text" class="app-pricing-tag"/>
              <p class="subscription">
                {{ t('shared_1-month-subscription') }}
              </p>
              <p class="price">
                  {{ oneMonth.formattedPrice }}
                <s
                  v-if="oneMonth.shouldShowStrikeThrough"
                  id="original-price"
                  >{{ oneMonth.originalPrice }}</s>
              </p>
              <p class="desc">
                {{ oneMonth.formattedPrice }}
                {{ t('shared_per-month-dot') }}
                {{ t('shared_billed-pmonthly') }}
              </p>
            </div>
            <div class="text-item">
              <SaleTag v-if="campaignData?.threeMonth?.text" :text="campaignData.threeMonth.text" class="app-pricing-tag"/>
              <p class="subscription">
                {{ t('shared_3-month-subscription') }}
              </p>
              <p class="price">
                  {{ threeMonth.formattedPrice }}
                <s
                  v-if="threeMonth.shouldShowStrikeThrough"
                  id="original-price"
                  >{{ threeMonth.originalPrice }}</s>
              </p>
              <p class="desc">
                {{ threeMonth.perMonthPrice }}
                {{ t('shared_per-month-dot') }}
                {{ t('shared_billed-every-3-months') }}
              </p>
            </div>
            <div class="text-item">
              <SaleTag v-if="campaignData?.twelveMonth?.text" :text="campaignData.twelveMonth.text" class="app-pricing-tag"/>
              <p class="subscription">
                {{ t('shared_12-month-subscription') }}
              </p>
              <p class="price">
                  {{ twelveMonth.formattedPrice }}
                <s
                  v-if="twelveMonth.shouldShowStrikeThrough"
                  id="original-price"
                  >{{ twelveMonth.originalPrice }}</s>
              </p>
              <p class="desc">
                {{ twelveMonth.perMonthPrice }}
                {{ t('shared_per-month-dot') }}
                {{ t('shared_billed-every-12-months') }}
              </p>
            </div>
          </div>
          <div
            class="buttons-group"
          >
            <p>{{ t('shared_download-app') }}</p>
            <div>
              <a
                :href="$appConfig.appleAppUrl"
                target="_blank"
                rel="nofollow"
                class="btn-store-outlined"
                @click="facebookGoogleAnalitycs(eventName, {
                  platform: 'App Store'
                })"
              ><AppStoreIcon />
              </a>
              <a
                :href="$appConfig.googleAppUrl"
                target="_blank"
                rel="nofollow"
                class="btn-store-outlined"
                @click="facebookGoogleAnalitycs(eventName, {
                  platform: 'Google Play'
                })"
              ><GoogePlayIcon />
              </a>
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="image-holder">
            <img
              class="phone"
              :src="pricing?.images[0]?.url"
              :alt="pricing?.images[0]?.imageMeta?.metaAltDescription"
              :title="pricing?.images[0]?.imageMeta?.metaTitle"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTranslation } from "i18next-vue";
import GoogePlayIcon from "@/assets/icons/GoogePlayIcon.vue";
import AppStoreIcon from "@/assets/icons/AppStoreIcon.vue";
import SaleTag from '../SaleTag.vue';

import {
  facebookGoogleAnalitycs,
} from "@/utils/helpers";

const { t } = useTranslation(['shared']);
const store = useStore();
const route = useRoute();

const prices = computed(() => store.state.prices?.prices);
const pricing = computed(() => store.state.pages?.pricing);

const isMobile = computed(() => store.state.device.isMobile);

const campaignData = computed(() => {
    return store.state.campaign?.subscriptions;
});

const oneMonth = computed(() => {
  const hasDiscount = !!campaignData.value?.oneMonth?.price;
  const price = hasDiscount ? campaignData.value?.oneMonth?.price : prices.value.appPricePerMonth;

  return {
    originalPrice: `${prices.value.currency}` + prices.value.appPricePerMonth,
    formattedPrice: `${prices.value.currency}` + price,
    shouldShowStrikeThrough: hasDiscount,
  }
});

const threeMonth = computed(() => {
  const hasDiscount = !!campaignData.value?.threeMonth?.price;
  const price = hasDiscount ? campaignData.value?.threeMonth?.price : prices.value.appPricePerThreeMonths;

  return {
    originalPrice: `${prices.value.currency}` + prices.value.appPricePerThreeMonths,
    formattedPrice: `${prices.value.currency}` + price,
    perMonthPrice: `${prices.value.currency}` + (price/3).toFixed(2),
    shouldShowStrikeThrough: hasDiscount,
  }
});

const twelveMonth = computed(() => {
  const hasDiscount = !!campaignData.value?.twelveMonth?.price;
  const price = hasDiscount ? campaignData.value?.twelveMonth?.price : prices.value.appPricePerYear;

  return {
    originalPrice: `${prices.value.currency}` + prices.value.appPricePerYear,
    formattedPrice: `${prices.value.currency}` + price,
    perMonthPrice: `${prices.value.currency}` + (price/12).toFixed(2),
    shouldShowStrikeThrough: hasDiscount,
  }
});

const eventName = computed(() => {
  let eventName = "app_store_click";
  if (route.name === 'pricing') eventName = "app_store_pricing";
  if (route.name === 'pricing-evo') eventName = "app_store_evo_pricing";
  return eventName;
});
</script>

<style lang="scss" scoped>

#original-price {
  color: $color-gray;
  font-size: 18px;
  font-weight: 500;
  margin-left: 6px;
}

.app-pricing-tag {
  margin-bottom: 6px;
}

</style>
