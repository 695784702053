<template>
  <div>
    <transition name="fade" mode="out-in">
      <div class="default-page">
        <div v-if="!loaded" class="loading-wrapper">
          <AppLoading />
        </div>
        <div v-else key="web-terms">
          <BreadCrumb />
          <div class="page">
            <div class="container" v-html="webTerms?.legalContent"></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import AppLoading from "../components/AppLoading";
import { createMetaInfo } from "@/utils/helpers";
import { useTranslation } from "i18next-vue";
import { useHead } from '@unhead/vue';

export default {
  components: {
    AppLoading,
    BreadCrumb,
  },
  setup() {
    const store = useStore();
    const { t } = useTranslation(['web-terms']);
    const loaded = ref(false);
    const webTerms = computed(() => store.state.pages.web_terms);

    onBeforeMount(() => {
      if (!store.state.pages.web_terms) {
        store.dispatch('pages/getPageByTag', { tag: 'web_terms' });
      }
    });

    onMounted(() => {
      document.body.classList.remove("overflow-hidden");
      loaded.value = true;
    });

    useHead(() => {
      return createMetaInfo({
        title: webTerms.value?.metaTitle,
        pageMeta: webTerms.value,
      });
    });

    return {
      t,
      webTerms,
      loaded,
    };
  },
};
</script>
