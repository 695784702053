import axios from 'axios';
import { storedLang } from "@/config/i18n.config";
import { GET_PAGE_BY_TAG } from '@/utils/queries';

export default {
    namespaced: true,
    state: {
        homepage: null,
        pricing: null,
        how_it_works: null,
        adapter: null,
        app: null,
        features: null,
        web_privacy: null,
        impressum: null,
        web_terms: null,
        supported_cars: null,
        app_privacy: null,
        app_terms: null,
        contact_us: null,
        tools: null,
        adapter_documents: null,
        about_us: null,
    },
    mutations: {
        homepage(state, payload) {
            state.homepage = payload;
        },
        pricing(state, payload) {
            state.pricing = payload;
        },
        how_it_works(state, payload) {
            state.how_it_works = payload;
        },
        adapter(state, payload) {
            state.adapter = payload;
        },
        app(state, payload) {
            state.app = payload;
        },
        features(state, payload) {
            state.features = payload;
        },
        impressum(state, payload) {
            state.impressum = payload;
        },
        web_privacy(state, payload) {
            state.web_privacy = payload;
        },
        web_terms(state, payload) {
            state.web_terms = payload;
        },
        supported_cars(state, payload) {
            state.supported_cars = payload;
        },
        app_privacy(state, payload) {
            state.app_privacy = payload;
        },
        app_terms(state, payload) {
            state.app_terms = payload;
        },
        contact_us(state, payload) {
            state.contact_us = payload;
        },
        tools(state, payload) {
            state.tools = payload;
        },
        adapter_documents(state, payload) {
            state.adapter_documents = payload;
        },
        about_us(state, payload) {
            state.about_us = payload;
        },
        reset(state) {
            state.homepage = null;
            state.pricing = null;
            state.how_it_works = null;
            state.adapter = null;
            state.app = null;
            state.features = null;
            state.impressum = null;
            state.web_privacy = null;
            state.web_terms = null;
            state.supported_cars = null;
            state.app_privacy = null;
            state.app_terms = null;
            state.contact_us = null;
            state.tools = null;
            state.adapter_documents = null;
            state.about_us = null;
        },
    },
    actions: {
        async getPageByTag({ commit, rootState: { language } }, { tag, resetPagesBeforeCommit = false }) {
            commit('loading', true, { root: true });

            try {
                const lang = language?.lang?.code || storedLang;
                const env = process.env.VUE_APP_WEB_CMS_ENV;

                if (!tag) {
                    return;
                };

                const query = GET_PAGE_BY_TAG(tag, lang, env);

                const { data } = await axios.post(process.env.VUE_APP_WEB_CMS, { query });

                const pageData = data.data.page.page;

                pageData.children = pageData.childPages;

                if (resetPagesBeforeCommit) {
                    commit('reset');
                }

                commit(tag, pageData);
            } catch (error) {
                console.error('Error in getPageByTag action:', error);
            } finally {
                commit('loading', false, { root: true });
            }
        }
    },
};
