<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onUnmounted, onBeforeMount, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import { useStore } from "vuex";
import Smartlook from 'smartlook-client';
import { useRoute, useRouter } from 'vue-router';
import { PAGE_ROUTE_NAMES } from '@/utils/constants';

const store = useStore();
const route = useRoute();
const router = useRouter();

const langCode = computed(() => store.state.language.lang?.code);

const modalsController = (keyCode) => {
  if (keyCode === 27) store.dispatch('resetModalStates');
  if (keyCode === 37) store.dispatch('modals/prevModal');
  if (keyCode === 39) store.dispatch('modals/nextModal');
};

watch(langCode, async (newVal, oldVal) => {
  if (!oldVal) {
    return;
  }

  store.commit('loading', true);

  await store.dispatch('pages/getPageByTag', { tag: PAGE_ROUTE_NAMES[route.name], resetPagesBeforeCommit: true });

  if (route.name !== 'home') {
    store.dispatch('pages/getPageByTag', { tag: 'homepage' });
  }

  if (route.name === 'home') {
    store.dispatch('pages/getPageByTag', { tag: 'tools' });
    store.dispatch('pages/getPageByTag', { tag: 'how_it_works' });
  }

  if (route.name === 'blog') {
    store.dispatch('pages/getPageByTag', { tag: 'tools' });
  }

  if (route.name === 'scanners') {
    store.dispatch('pages/getPageByTag', { tag: 'app' });
  }

  if (route.name === 'app' || route.name === 'scanners') {
    await store.dispatch('pages/getPageByTag', { tag: 'how_it_works' });
    await store.dispatch('pages/getPageByTag', { tag: 'pricing' });
  }

  store.commit('loading', false);
});

onBeforeMount(async () => {
  await router.isReady();

  setTimeout(() => {
    if (route.name !== 'custom-vehicle-selector') {
      store.dispatch('init');
    }

    document.addEventListener('keyup', (e) => modalsController(e.keyCode));
  }, 100);
});

onMounted(async () => {
  await router.isReady();

  if (route.name == 'custom-vehicle-selector') {
    return;
  }

  Smartlook.init('8c903982d0581e97c22a897eab351e5e7391c43f', { region: 'eu' });
})

onUnmounted(() => {
  document.removeEventListener('keyup', modalsController);
});
</script>
