<template>
    <div class="app-pricing-card content-item">
        <!-- Title and Subtitle -->
        <div class="title-subtitle-wrapper">
            <p v-html="title" class="title"></p>

            <p class="subtitle">
                {{ subtitle }}
            </p>
        </div>
        <!-- Subscription options and Image -->
        <div class="plans-and-image-wrapper">
            <SubscriptionPlans
                :plans="subscriptionPlans"
                :currency="prices?.currency || ''"
            />
            <img
                class="phone"
                :src="pricing?.images[0]?.url"
                :alt="pricing?.images[0]?.imageMeta?.metaAltDescription"
                :title="pricing?.images[0]?.imageMeta?.metaTitle"
                loading="lazy"
            />
        </div>
        <!-- Download buttons (App Store / Google Play) -->
        <span class="download-text">{{ t('shared_download-app') }}</span>
        <AppStoreLinks :eventName="eventName" class="app-store-links" />
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';
import SubscriptionPlans from '@/components/UI-elements/SubscriptionPlans.vue';
import AppStoreLinks from '../AppStoreLinks.vue';
import { formatPhraseWithHtml } from '@/utils/helpers';

const { t } = useTranslation(['shared']);
const store = useStore();

const prices = computed(() => store.state.prices?.prices);
const pricing = computed(() => store.state.pages?.pricing);

const title = computed(() => {
    const text = t('shared_get-carista-app');
    const gradient = t('shared_get-carista-app-gradient');

    const className = 'text-gradient';

    return formatPhraseWithHtml(text, gradient, 'span', className);
});

const subtitle = computed(() =>
    t('shared_you-need-both-to-start-upgrading-your-car-experience')
);

const eventName = computed(() => {
    const event = 'app_store_click';

    return event;
});

const campaignData = computed(() => {
    return store.state.campaign?.subscriptions;
});

const subscriptionPlans = computed(() => {
    if (!prices.value) {
        return [];
    }

    const oneMonthDiscount = campaignData.value?.oneMonth?.price;
    const threeMonthDiscount = campaignData.value?.threeMonth?.price;
    const twelveMonthDiscount = campaignData.value?.twelveMonth?.price;

    return [
        {
            label: t('shared_1-month-subscription'),
            tag: campaignData.value?.oneMonth?.text,
            mainPrice: `${prices.value.currency}${oneMonthDiscount ?? prices.value.appPricePerMonth}`,
            originalPrice: `${prices.value.currency}${prices.value.appPricePerMonth}`,
            shouldShowStrikeThrough: Boolean(oneMonthDiscount),
            descLine1: `${prices.value.currency}${
                oneMonthDiscount ?? prices.value.appPricePerMonth
            } ${t('shared_per-month-dot')}`,
            descLine2: t('shared_billed-pmonthly'),
        },
        {
            label: t('shared_3-month-subscription'),
            tag: campaignData.value?.threeMonth?.text,
            mainPrice: `${prices.value.currency}${threeMonthDiscount ?? prices.value.appPricePerThreeMonths}`,
            originalPrice: `${prices.value.currency}${prices.value.appPricePerThreeMonths}`,
            shouldShowStrikeThrough: Boolean(threeMonthDiscount),
            descLine1: `${prices.value.currency}${(
                (threeMonthDiscount ?? prices.value.appPricePerThreeMonths)/3
            ).toFixed(2)} ${t('shared_per-month-dot')}`,
            descLine2: t('shared_billed-every-3-months'),
        },
        {
            label: t('shared_12-month-subscription'),
            tag: campaignData.value?.twelveMonth?.text,
            mainPrice: `${prices.value.currency}${twelveMonthDiscount ?? prices.value.appPricePerYear}`,
            originalPrice: `${prices.value.currency}${prices.value.appPricePerThreeMonths}`,
            shouldShowStrikeThrough: Boolean(twelveMonthDiscount),
            descLine1: `${prices.value.currency}${(
                (twelveMonthDiscount ?? prices.value.appPricePerYear) / 12
            ).toFixed(2)} ${t('shared_per-month-dot')}`,
            descLine2: t('shared_billed-every-12-months'),
        },
    ];
});
</script>

<style lang="scss" scoped>
.app-pricing-card {
    display: flex;
    flex-direction: column;

    background-color: $color-white;
    max-width: 660px;
    width: 33%;

    .title {
        font-size: 40px;
        font-weight: 500;
        color: $color-neutral-deep;
        margin-bottom: 22px;

        @media (max-width: $screen-md) {
            font-size: 32px;
        }
    }

    .subtitle {
        font-size: 16px !important;
        padding: 0 0 30px !important;
        border-bottom: 1px solid $color-neutral-light;
        color: $color-neutral-deep !important;
    }

    .plans-and-image-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 35px 0 auto;

        @media (max-width: $screen-md) {
            margin: 35px 0 24px;
        }

        img {
            width: 33%;
            height: fit-content;

            @media (max-width: 1800px) {
                display: none;
            }
        }
    }

    .download-text {
        margin-bottom: 18px;
        font-size: 16px;
    }

    .app-store-links {
        align-self: flex-start;
        margin: 0px;
        gap: 20px;
    }
}
</style>
