<template>
    <div
    v-if="text"
        :style="{
            'border-radius': borderRadius + 'px',
        }"
        :class="[{
            reverse: reverseColors,
        }, gradientClass]"
        class="sale-tag"
    >
        {{ text }}
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';

const store = useStore();

const { t } = useTranslation(['shared']);

const props = defineProps({
    text: String,
    borderRadius: {
        type: Number,
        default: 26,
    },
    reverseColors: {
        type: Boolean,
        default: false,
    },
});

const gradientClass = computed(() => {
    const background = store.state.campaign?.meta?.backgroundColor;

    if (!background) {
        return 'bg-sale-gradient'
    }

    return `bg-${background.toLowerCase()}-gradient`;
});
</script>

<style lang="scss" scoped>
.sale-tag {
    display: inline-block;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
    width: fit-content;

    &.reverse {
        background: $color-white;
    }

    &:not(.reverse) {
        color: $color-white;
    }
}
</style>
